import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import RewardLevelHourly from '../phala/charts/phala.widget.RewardLevelHourly';

import ResponsiveByHour from '../phala/charts/workers/phala.widget.ResponsiveByHour';
import BlockTimeByHour from '../phala/charts/phala.widget.blocktime';
import Workers from '../phala/charts/workers/phala.sideWidget.workers';
import NewEvents from '../phala/charts/events/phala.widgets.newEvents';
import Wallets from '../phala/charts/wallets/phala.widget.wallets';
import HalvingEstimate from '../phala/charts/phala.widget.halving';
import AprByLevelMobile from '../phala/mobileCharts/phala.widget.AprByLevel.Mobile';
import Transfers from '../phala/charts/transfers/phala.widget.transfers';
import HalvingProgress from '../phala/charts/halving/phala.widget.halving';
import AprByLevel from '../phala/charts/apr/phala.widget.AprByLevel';
import AprByStake from '../phala/charts/apr/phala.widget.AprByStake';
import Aprs from '../phala/charts/apr/phala.sideWidget.Apr';
import PoolsCreated from '../phala/charts/phala.widget.poolsCreated';
import NetworkWidgets from '../phala/widgets/networkWidgets';
import Pools from '../phala/charts/pools/phala.sideWidget.pools';
import WorkerWidgets from '../phala/widgets/workerWidgets';
import Header from '../../Header';
import Footer from '../../footer';
import {
  getLastMinerBlock,
  getHourlyStats,
  getCurrentStats
} from '../phala/phalaSlice';
import { useMediaQuery } from '@material-ui/core';
import '../../App.css';

//Components
const Home = () => {
  const dispatch = useDispatch();
  const { networkChart, aprChartType, workerChart } = useSelector(
    store => store.phala
  );
  useEffect(() => {
    dispatch(getLastMinerBlock());
    let end = Math.floor(new Date().getTime());
    let start = Math.floor(end - 604800000); // 1 week
    let data = {
      start: start,
      end: end,
      interval: 'hour'
    };

    dispatch(getHourlyStats(data));
    dispatch(getCurrentStats());
  }, []);

  return (
    <>
      <Header />
      <section class="main-layout d-flex justify-content-center">
        <>
          <h1 className="text-center pb-3 text-subtle pt-3">Khala On-Chain Data</h1>
          <div className="container-fluid row m-0 p-0 d-flex justify-content-center">
            {networkChart === 'Block' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <BlockTimeByHour />
              </div>
            )}
            {networkChart === 'Worker' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <Workers />
              </div>
            )}

            {networkChart === 'Events' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <NewEvents />
              </div>
            )}
            {networkChart === 'Wallets' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <Wallets />
              </div>
            )}
            {networkChart === 'Rewards' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <RewardLevelHourly />
              </div>
            )}
            {networkChart === 'Pools' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <Pools />
              </div>
            )}
            {networkChart === 'Transfers' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <Transfers />
              </div>
            )}
            {networkChart === 'Halving' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <HalvingProgress />
              </div>
            )}
            {networkChart === 'Apr' && (
              <div className="col-lg-6 col-12 mobileLeftContainer pt-2">
                <Aprs />
              </div>
            )}

            <div className="col-lg-6 col-12 mobileLeftContainer d-flex justify-content-center">
              <NetworkWidgets />
            </div>
          </div>
        </>
      </section>
      <Footer />
    </>
  );
};

export default Home;
