import React, { useEffect, useState,useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import '../../App.css';
// Components
import Header from '../../Header';
import Footer from '../../footer';
import { getOrgProfile } from '../phala/phalaSlice';
import AccountList from '../phala/org/phalaOrg.widget.accountList';
import PoolList from '../phala/org/phalaOrg.widget.poolList';
import AccountListWidgets from '../phala/widgets/accountListWidgets';
import ProjectedRewards from '../phala/financialModels/projectedRewards';


const AccountHome = () => {
  let navigate = useNavigate();
  const [componentConfig, setcomponentConfig] = useState([]);
  const { currentUser} = useSelector((store) => store.user);
  const { accountCharts,orgProfile } = useSelector((store) => store.phala);
  const dispatch = useDispatch();
  const poolList = <PoolList />;
  const accountList = <AccountList />;

  const genComponentList = () => {
    let cnfg = [];
    const tmpjson = {
      name: 'AccountList',
      md: 6,
      xxl: 4,
      jsx: <AccountList />
    };
    cnfg.push(tmpjson);
    const tmpjson1 = {
      name: 'PoolList',
      md: 6,
      xxl: 4,
      jsx: <PoolList />
    };
    cnfg.push(tmpjson1);
    //console.log(componentConfig);
    setcomponentConfig(cnfg);
  };
  useEffect(() => {
    
    genComponentList();
    console.log('useEffect before getOrgProfile');
    if (orgProfile.org_id == "")
      dispatch(getOrgProfile(currentUser.token));
  }, []);
  

  return (
    <>
    
      <Header />
      <div class='main-layout'>
        
  <section class='content-wrap' >
      <div className="row container-fluid d-flex justify-content-center">
        <h2 class='pt-4 ps-5'>Phala Wallets</h2>
        <div class='row'>
          <div class='col'>
            <AccountListWidgets/>
          </div>
                {accountCharts === "accountTable" && ( 
                  <div className="col">
                    <AccountList />
                  </div>
                )}
                {accountCharts === "accountPools" && ( 
                  <div className="col">
                   <PoolList />
                  </div>
                )}
          
        </div>
      </div>
  </section>

  
  </div>
  <Footer/>
  </>
  );
};

export default AccountHome;
