import React, { useEffect, useState } from 'react';

import { ButtonGroup, Button } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRewardsByHour,
  getAprByStake,
  setWorkerChartTitle,
  getAprByLevel
} from '../phalaSlice';

const RewardLevelHourly = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [Level1Array, setLevel1] = useState([]);
  const [Level2Array, setLevel2] = useState([]);
  const [Level3Array, setLevel3] = useState([]);
  const [Level4Array, setLevel4] = useState([]);
  const [Level5Array, setLevel5] = useState([]);

  const [titleText, setTitle] = useState('7d');
  const [chartInterval, setChartInterval] = useState('hour');

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { rewardsByHour, rewardsIsLoading, workerChartTitle } = useSelector(
    store => store.phala
  );
  const lvl1RewardWidget = rewardsByHour.reduce(
    (acc, cur) => acc + cur.rewardsLevel1,
    0
  );
  const lvl2RewardWidget = rewardsByHour.reduce(
    (acc, cur) => acc + cur.rewardsLevel2,
    0
  );
  const lvl3RewardWidget = rewardsByHour.reduce(
    (acc, cur) => acc + cur.rewardsLevel3,
    0
  );
  const lvl4RewardWidget = rewardsByHour.reduce(
    (acc, cur) => acc + cur.rewardsLevel4,
    0
  );
  const lvl5RewardWidget = rewardsByHour.reduce(
    (acc, cur) => acc + cur.rewardsLevel5,
    0
  );

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'bottom', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
      }
    },
    toolbox: {
      feature: {},
      iconStyle: {
        borderColor: 'white'
      }
    },
    legend: {
      data: ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'],
      top: '25',
      inactiveColor: '#555555',
      top: 'auto',
      textStyle: {
        color: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          opacity: 0.08 // set the opacity of the line to 50%
        }
      },
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    series: [
      {
        name: 'Level 1',
        data: Level1Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#5C3ABD'
      },
      {
        name: 'Level 2',
        data: Level2Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#4FB3BF'
      },
      {
        name: 'Level 3',
        data: Level3Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#D1BE65'
      },
      {
        name: 'Level 4',
        data: Level4Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#E88834'
      },
      {
        name: 'Level 5',
        data: Level5Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#AD5CBD'
      }
    ]
  };
  useEffect(() => {
    console.log('data Changed');
    let l1 = [];
    let l2 = [];
    let l3 = [];
    let l4 = [];
    let l5 = [];
    let tcat = [];
    for (let m of rewardsByHour) {
      l1.push(parseInt(m.rewardsLevel1));
      l2.push(parseInt(m.rewardsLevel2));
      l3.push(parseInt(m.rewardsLevel3));
      l4.push(parseInt(m.rewardsLevel4));
      l5.push(parseInt(m.rewardsLevel5));
      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,

            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);
    setLevel1(l1);
    setLevel2(l2);
    setLevel3(l3);
    setLevel4(l4);
    setLevel5(l5);
  }, [rewardsByHour]);

  useEffect(() => {
    let end = Math.floor(new Date().getTime());

    let start = Math.floor(end - 604800000); // 1 week
    let data = {};
    if (workerChartTitle === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (workerChartTitle === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (workerChartTitle === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('30d');
    }
    if (workerChartTitle === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getRewardsByHour(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (e === '24h') {
      setTitle('24h');
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (e === '7d') {
      setTitle('7d');
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (e === '30d') {
      setTitle('30d');
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('30d');
    }
    if (e === '90d') {
      setTitle('90d');

      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getRewardsByHour(data));

    dispatch(setWorkerChartTitle(e));
  };

  return (
    <section className="leftCard">
      <div className="row">
        <h3 className="text-start col-10">Reward Distribution</h3>
        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
          </ButtonGroup>
        </div>

        <div>
          {rewardsIsLoading === true ? (
            <div class="position-absolute bottom-50 end-50">
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          ) : (
            ''
          )}

          <div>
            <ReactEChart option={eChartsOption} />
          </div>

          
          <div className="d-flex justify-content-center">
          <div className="row d-flex align-content-center  pt-4 pb-4 mt-4 leftCard-data-container">
            <card className="col text-center ps-4">
              <h6 class="text-subtle">Level 1</h6>
              <h4>
                {Number(lvl1RewardWidget.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1 text-secondary">
                  <small>PHA</small>
                </sub>
              </h4>
            </card>
            <card className="col text-center">
              <h6 class="text-subtle"> Level 2</h6>
              <h4>
                {Number(lvl2RewardWidget.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1 text-secondary">
                  <small>PHA</small>
                </sub>
              </h4>
            </card>
            <card className="col text-center">
              <h6 class="text-subtle"> Level 3</h6>
              <h4>
                {Number(lvl3RewardWidget.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1 text-secondary">
                  <small>PHA</small>
                </sub>
              </h4>
            </card>
            <card className="col text-center">
              <h6 class="text-subtle">Level 4</h6>
              <h4>
                {Number(lvl4RewardWidget.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1 text-secondary">
                  <small>PHA</small>
                </sub>
              </h4>
            </card>
            <card className="col text-center pe-4">
              <h6 class="text-subtle">Level 5</h6>
              <h4>
                {Number(lvl5RewardWidget.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1 text-secondary">
                  <small>PHA</small>
                </sub>
              </h4>
            </card>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default RewardLevelHourly;
