import React, { useEffect, useState } from 'react';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setPoolChart } from '../../phalaSlice';
import WhitelistWidget from '../pools/phala.widget.whitelist';
import RewardsWidget from './phala.widget.rewards';
import WithdrawnWidget from './phala.widget.withdrawn';
import PoolsWidget from '../pools/phala.widget.pools';
import '../../../../App.css';

const Pools = () => {
  const dispatch = useDispatch();

  const { hourlyStats, poolChart } = useSelector(store => store.phala);

  const poolWhitelistCreated = hourlyStats.reduce(
    (acc, cur) => acc + cur.poolWhitelistCreated,
    0
  );
  const poolWhitelistDeleted = hourlyStats.reduce(
    (acc, cur) => acc + cur.poolWhitelistDeleted,
    0
  );
  const poolWhitelistStakerAdded = hourlyStats.reduce(
    (acc, cur) => acc + cur.poolWhitelistStakerAdded,
    0
  );
  const poolWhitelistStakerRemoved = hourlyStats.reduce(
    (acc, cur) => acc + cur.poolWhitelistStakerRemoved,
    0
  );

  //Delegation
  const poolWithdrawal = hourlyStats.reduce(
    (acc, cur) => acc + cur.poolWithdrawal,
    0
  );
  const poolOwnerRewardsWithdrawn = hourlyStats.reduce(
    (acc, cur) => acc + cur.ownerRewardsWithdrawn,
    0
  );

  //Rewards
  const poolOwnerRewards = hourlyStats.reduce(
    (acc, cur) => acc + cur.rewardsToOwner,
    0
  );
  const poolDelegatorRewards = hourlyStats.reduce(
    (acc, cur) => acc + cur.rewardsToStaker,
    0
  );

  //Pools
  const poolsCreated = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumPoolCreated,
    0
  );

  const setPoolChartHandler = poolChartName => {
    dispatch(setPoolChart(poolChartName));
  };

  return (
    <section className="leftCard">
      <div>
        {poolChart === 'Whitelist' && (
          <div className="col m-0 p-0">
            <WhitelistWidget />
          </div>
        )}
        {poolChart === 'Pools' && (
          <div className="col m-0 p-0">
            <PoolsWidget />
          </div>
        )}
        {poolChart === 'Rewards' && (
          <div className="col m-0 p-0">
            <RewardsWidget />
          </div>
        )}
        {poolChart === 'Withdrawn' && (
          <div className="col m-0 p-0">
            <WithdrawnWidget />
          </div>
        )}
      </div>
      <section className="justify-content-center pt-3 text-white">
        <div className="d-flex justify-content-center pt-3 text-white">
        <div className="row leftCard-data-container pb-3 d-flex align-items-center text-subtle">
          <h5 className="col pt-3 pb-2 ms-2 pe-3  text-subtle">Rewards</h5>
          <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setPoolChartHandler('Rewards')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          <div className="row text-center height-adjustment">
            <div className="col">
              <h6 className="text-subtle">Delegator</h6>
              <h4>
                {Number(poolDelegatorRewards.toFixed(0)).toLocaleString(
                  'en-US'
                )}
              </h4>
            </div>
            <div className="col">
              <h6 className="text-subtle">Owner</h6>
              <h4>
                {Number(poolOwnerRewards.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
          </div>

        </div>
        </div>

        <div className="d-flex justify-content-center pt-3 text-white">
        <div className="row leftCard-data-container pb-3 d-flex align-items-center text-subtle">
          <h5 className="col pt-3 pb-2 ms-2 pe-3  text-subtle">Withdrawn</h5>
          <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setPoolChartHandler('Withdrawn')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          <div className="row text-center height-adjustment">
            <div className="col">
              <h6 className="text-subtle">Delegator</h6>
              <h4>
                {Number(poolWithdrawal.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col">
              <h6 className="text-subtle">Owner</h6>
              <h4>
                {Number(poolOwnerRewardsWithdrawn.toFixed(0)).toLocaleString(
                  'en-US'
                )}
              </h4>
            </div>
          </div>

        </div>
        </div>
        
        <div className="d-flex justify-content-center pt-3 text-white">
        <div className="row leftCard-data-container pb-3 d-flex align-items-center text-subtle">
            <div className="d-flex align-items-center text-subtle">
          <h5 className="col pt-3 pb-2 ms-2 pe-3  text-subtle">Whitelist</h5>
          <i class="fa-solid fa-chart-line"></i>
          <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setPoolChartHandler('Whitelist')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <h6 className="text-subtle">Created</h6>
              <h4>
                {Number(poolWhitelistCreated.toFixed(2)).toLocaleString(
                  'en-US'
                )}
              </h4></div>
              <div className="col">
              <h6 className="text-subtle">Deleted</h6>
              <h4>
                {Number(poolWhitelistDeleted.toFixed(2)).toLocaleString(
                  'en-US'
                )}
              </h4>
            </div>
            <div className="col">
              <h6 className="text-subtle">Staker Added</h6>
              <h4>
                {Number(poolWhitelistStakerAdded.toFixed(2)).toLocaleString(
                  'en-US'
                )}
              </h4></div>
              <div className="col">
              <h6 className="text-subtle">Staker Removed</h6>
              <h4>
                {Number(poolWhitelistStakerRemoved.toFixed(2)).toLocaleString(
                  'en-US'
                )}
              </h4>
              
            </div>
          </div>

          </div>
        </div>
      </section>
    </section>
  );
};

export default Pools;
