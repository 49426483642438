import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

const baseUrl = process.env.REACT_APP_PHALASERVER + '/phala';
const baseUrlWorker = process.env.REACT_APP_PHALASERVER + '/worker';
const baseUrlMinerBlock = process.env.REACT_APP_PHALASERVER + '/minerBlock';

const initialState = {
  phala: [],
  phalaSelected: [],
  hourlyStats: [],
  currentStats: {},
  currentStatsStatus: '',
  lastMinerBlock: {},
  minerBlocksHourly: [],
  orgProfiles: [],
  orgProfile: {
    org_id: '',
    accounts: [],
    pools: []
  },
  workerLogs: [],
  workerLog:{
    
  },
  workerSettlements: [],
  isLogsLoading: false,
  showOrgs: false,
  searchQuery: '',
  searchState: 'All',
  halving: {
    _id: '',
    nextBlock: 0,
    lastHalfBlock: 0,
    halvingInterval: 0,
    nextHalvingBlock: 0,
    averageBlockTime: 0,
    halvingDate: 0
  },
  phalaPrice: {
    _id: '',
    token: '',
    price: 0,
    timestamp: 0,
    source: ''
  },
  networkChart: 'Block',
  networkChartTitle: '24h',
  workerChartTitle: '24h',
  averageDenominator: 7,
  workerChart: 'startStop',
  poolChart: 'Rewards',
  aprChart: 'Delegation',
  aprChartType: 'aprStakeChart',
  accountCharts: 'accountTable',
  selAccount: {},
  responsiveByHour: [],
  responsiveIsLoading: false,
  rewardsByHour: [],
  rewardsIsLoading: false,
  blockTimeByHour: [],
  blockTimeIsLoading: false,
  aprByLevel: [],
  aprByLevelIsLoading: false,
  aprByStake: [],
  aprByStakeIsLoading: false,
  accountDetails: {
    account: '',
    free: 0,
    reserved: 0,
    miscFrozen: 0,
    feeFrozen: 0,
    delegation: [],
    pools: [],
    transferTo: [],
    transferFrom: []
  },
  lastTime: 0,
  isLoading: false,
  hasLoaded: false, // set if the phala array has been loaded atleast once.
  selPhala: {},
  rightMenu: false
};

export const getCurrentStats = createAsyncThunk(
  'phala/getCurrentStats',
  async thunkAPI => {
    try {
      const url = baseUrlMinerBlock + '/getCurrentStats';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);


export const getHourlyStats = createAsyncThunk(
  'phala/getHourlyStats',
  async (data, thunkAPI) => {
    try {
      const url =
        baseUrlMinerBlock +
        '/getHourlyStats?start=' +
        String(data.start) +
        '&end=' +
        String(data.end) +
        '&interval=' +
        data.interval;
      console.log(url);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getPhalaPrice = createAsyncThunk(
  'phala/getPhalaPrice',
  async (thunkAPI) => {
    let data = {token: 'pha'}
    try {
      const url = baseUrl + '/getTokenPrice';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data );

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getWorkerLogs = createAsyncThunk(
  'phala/getWorkerLogs',
  async (data, thunkAPI) => {
    try {
      const url = baseUrlWorker + '/getWorkerLogs';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getWorkerSettlement = createAsyncThunk(
  'phala/getWorkerSettlement',
  async (data, thunkAPI) => {
    try {
      const url = baseUrlWorker + '/getWorkerSettlement';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getHalving = createAsyncThunk(
  'phala/getHalving',
  async thunkAPI => {
    try {
      const url = baseUrlMinerBlock + '/GetHalving';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getAccountDetails = createAsyncThunk(
  'phala/getAccountDetails',
  async (account, thunkAPI) => {
    console.log('thunk getAccountDetails');
    try {
      const url = baseUrl + '/getAccountDetails?account_id=' + account;
      console.log('getAccountDetails');
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const bulkServerCommands = createAsyncThunk(
  'phala/bulkServerCommands',
  async (data, thunkAPI) => {
    console.log('thunk bulkServerCommands');

    try {
      const url = baseUrl + '/bulkServerCommands';
      console.log(data);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.cmds, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getOrgProfile = createAsyncThunk(
  'phala/getOrgProfile',
  async (data, thunkAPI) => {
    console.log('thunk getOrgProfile');

    try {
      const url = baseUrl + '/getOrgProfile';
      console.log("orgProfile " + data);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.profile, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getOrgProfiles = createAsyncThunk(
  'phala/getOrgProfiles',
  async (data, thunkAPI) => {
    console.log('thunk getOrgProfiles');
    console.log("orgProfiles" + data);
    let tdata = {};
    try {
      const url = baseUrl + '/getOrgProfiles';
      console.log(data);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, tdata, {
        headers: { authorization: data }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getMinerBlocksHourly = createAsyncThunk(
  'phala/getMinerBlocksHourly',
  async (data, thunkAPI) => {
    try {
      const url =
        baseUrlMinerBlock +
        '/GetHourlyMinerBlocksStats?start=2022-11-10&end=2022-11-17';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getLastMinerBlock = createAsyncThunk(
  'phala/getLastMinerBlock',
  async thunkAPI => {
    try {
      const url = baseUrlMinerBlock + '/GetLastMinerBlock';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getAllPhala = createAsyncThunk(
  'phala/getAllPhala',
  async (data, thunkAPI) => {
    try {

      const url = baseUrl + '/getPhalaServers';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,data.org, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const updateSetSendNotification = createAsyncThunk(
  'phala/updateSetSendNotification',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/updateSetSendNotification';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const updateSetAutoRestart = createAsyncThunk(
  'phala/updateSetAutoRestart',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/updateSetAutoRestart';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const updateSendRestartCommand = createAsyncThunk(
  'phala/updateSendRestartCommand',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/setRestartCommand';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const sendServerCommand = createAsyncThunk(
  'phala/sendServerCommand',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/sendServerCommand';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const sendServerCommandList = createAsyncThunk(
  'phala/sendServerCommand',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/sendServerCommandList';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const updateSendStopCommand = createAsyncThunk(
  'phala/updateSendStopCommand',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/sendStopCommand';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const updateSendStartCommand = createAsyncThunk(
  'phala/updateSendStartCommand',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/sendStartCommand';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const removeWorker = createAsyncThunk(
  'phala/removeWorker',
  async (data, thunkAPI) => {
    try {
      const url = baseUrlWorker + '/removeWorker';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const addWorker = createAsyncThunk(
  'phala/updateSendStartCommand',
  async (data, thunkAPI) => {
    try {
      const url = baseUrlWorker + '/addWorker';
      //console.log(cUser.token);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getResponsive = createAsyncThunk(
  'phala/getResponsive',
  async (data, thunkAPI) => {
    try {
      const url =
        baseUrlMinerBlock +
        '/getResponsive?start=' +
        String(data.start) +
        '&end=' +
        String(data.end) +
        '&interval=' +
        data.interval;
      console.log(url);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getRewardsByHour = createAsyncThunk(
  'phala/getRewardsByHour',
  async (data, thunkAPI) => {
    try {
      const url =
        baseUrlMinerBlock +
        '/getWorkerRewards?start=' +
        String(data.start) +
        '&end=' +
        String(data.end) +
        '&interval=' +
        data.interval;
      console.log(url);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getBlockTime = createAsyncThunk(
  'phala/getBlockTime',
  async (data, thunkAPI) => {
    try {
      const url =
        baseUrlMinerBlock +
        '/getBlockTime?start=' +
        String(data.start) +
        '&end=' +
        String(data.end) +
        '&interval=' +
        data.interval;
      console.log(url);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getAprByLevel = createAsyncThunk(
  'phala/getAprByLevel',
  async (data, thunkAPI) => {
    try {
      const url =
        baseUrlMinerBlock +
        '/getAprByLevel?start=' +
        String(data.start) +
        '&end=' +
        String(data.end) +
        '&interval=' +
        data.interval;
      console.log(url);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getAprByStake = createAsyncThunk(
  'phala/getAprByStake',
  async (data, thunkAPI) => {
    try {
      const url =
        baseUrlMinerBlock +
        '/getAprByStake?start=' +
        String(data.start) +
        '&end=' +
        String(data.end) +
        '&interval=' +
        data.interval;
      console.log(url);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const addOrgAccess = createAsyncThunk(
  'phala/addAccount',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/addOrgAccess';
      console.log(data.token);
      console.log(data.phala);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const addAccount = createAsyncThunk(
  'phala/addAccount',
  async (data, thunkAPI) => {
    try {
      const url = baseUrl + '/addOrgAccount';
      console.log(data.token);
      console.log(data.phala);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.phala, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const addOrgProfile = createAsyncThunk(
  'phala/addOrgProfile',
  async (data, thunkAPI) => {
    console.log('thunk getOrgProfile');

    try {
      const url = baseUrl + '/addOrgProfile';
      console.log(data);
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.profile, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
const phalaSlice = createSlice({
  name: 'phala',
  initialState,
  reducers: {
    togglePhalaSelected: (state, action) => {
      //check if the action.payload is already in the array. Add if not.
      if (!state.phalaSelected.includes(action.payload)) {
        state.phalaSelected.push(action.payload);
      } else {
        //remove the action.payload from the array.
        state.phalaSelected = state.phalaSelected.filter(
          item => item !== action.payload
        );
      }
    },
    removeAllPhalaSelected: (state, action) => {
      //remove the action.payload from the array.
      state.phalaSelected = []
      
    },
    addAllPhalaSelected: (state, action) => {
      // iterate through action.payload and add to phalaSelected
      state.phalaSelected = []
      action.payload.forEach(element => {
       
          state.phalaSelected.push(element.serviceName);
       
      }
      );
    },
      
    setWorkerLog: (state, action) => {
      state.workerLog = action.payload;
    },
    setLastTime: (state, action) => {
      state.lastTime = action.payload;
    },
    setSearchState: (state, action) => {
      state.searchState = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setShowOrgs: (state, action) => {
      state.showOrgs = action.payload;
    },
    setNetworkChart: (state, action) => {
      state.networkChart = action.payload;
    },
    setAprChartType: (state, action) => {
      state.aprChartType = action.payload;
    },
    setAccountCharts: (state, action) => {
      state.accountCharts = action.payload;
    },
    setNetworkChartTitle: (state, action) => {
      state.networkChartTitle = action.payload;
      if (action.payload === '24h') {
        state.averageDenominator = 24;
      }
      if (action.payload === '7d') {
        state.averageDenominator = 7;
      }
    },
    setWorkerChartTitle: (state, action) => {
      state.workerChartTitle = action.payload;
    },
    setPoolChart: (state, action) => {
      state.poolChart = action.payload;
    },
    setAprChart: (state, action) => {
      state.aprChart = action.payload;
    },
    setWorkerChart: (state, action) => {
      state.workerChart = action.payload;
    },
    updateSelPhala: (state, action) => {
      state.selPhala = action.payload;
    },
    updateSelAccount: (state, action) => {
      state.selAccount = action.payload;
    },
    rightMenuShow: state => {
      state.rightMenu = true;
    },
    rightMenuHide: state => {
      state.rightMenu = false;
    }
  },
  extraReducers: {
    [removeWorker.pending]: state => {
      state.isLoading = true;
      
    },
    [removeWorker.fulfilled]: (state, action) => {
      state.isLoading = false;
      
    },
    [removeWorker.rejected]: (state, action) => {
      state.isLoading = false;
      
    },

    
    [getCurrentStats.pending]: state => {
      state.isLogsLoading = true;
      state.currentStatsStatus = "loading";
    },
    [getCurrentStats.fulfilled]: (state, action) => {
      state.isLogsLoading = false;
      state.currentStatsStatus = "loaded";
      state.currentStats = action.payload
    },
    [getCurrentStats.rejected]: (state, action) => {
      state.currentStatsStatus = "error";
      state.isLogsLoading = false;
    },



    [getHourlyStats.pending]: state => {
      state.isLogsLoading = true;
    },
    [getHourlyStats.fulfilled]: (state, action) => {
      state.isLogsLoading = false;
      state.hourlyStats = action.payload
    },
    [getHourlyStats.rejected]: (state, action) => {
      state.isLogsLoading = false;
    },




    [getPhalaPrice.pending]: state => {
      state.isLogsLoading = true;
    },
    [getPhalaPrice.fulfilled]: (state, action) => {
      state.isLogsLoading = false;
      state.phalaPrice = action.payload
    },
    [getPhalaPrice.rejected]: (state, action) => {
      state.isLogsLoading = false;
    },


    [getWorkerSettlement.pending]: state => {
      state.isLogsLoading = true;
    },
    [getWorkerSettlement.fulfilled]: (state, action) => {
      state.isLogsLoading = false;
      state.workerSettlements = action.payload
    },
    [getWorkerSettlement.rejected]: (state, action) => {
      state.isLogsLoading = false;
    },

    [bulkServerCommands.pending]: state => {
      state.isLogsLoading = true;
    },
    [bulkServerCommands.fulfilled]: (state, action) => {
      state.isLogsLoading = false;
      
    },
    [bulkServerCommands.rejected]: (state, action) => {
      state.isLogsLoading = false;
    },

    [getWorkerLogs.pending]: state => {
      state.isLogsLoading = true;
    },
    [getWorkerLogs.fulfilled]: (state, action) => {
      state.isLogsLoading = false;
      state.workerLogs = action.payload
    },
    [getWorkerLogs.rejected]: (state, action) => {
      state.isLogsLoading = false;
    },


    [sendServerCommand.pending]: state => {
      //state.aprByStakeIsLoading = true;
    },
    [sendServerCommand.fulfilled]: (state, action) => {
      //state.aprByStakeIsLoading = false;
      
    },
    [sendServerCommand.rejected]: (state, action) => {
      //state.aprByStakeIsLoading = false;
    },

    [sendServerCommandList.pending]: state => {
      //state.aprByStakeIsLoading = true;
    },
    [sendServerCommandList.fulfilled]: (state, action) => {
      //state.aprByStakeIsLoading = false;
      
    },
    [sendServerCommandList.rejected]: (state, action) => {
      //state.aprByStakeIsLoading = false;
    },

    [getHalving.pending]: state => {
      //state.aprByStakeIsLoading = true;
    },
    [getHalving.fulfilled]: (state, action) => {
      //state.aprByStakeIsLoading = false;
      state.halving = action.payload;
    },
    [getHalving.rejected]: (state, action) => {
      //state.aprByStakeIsLoading = false;
    },

    [addOrgAccess.pending]: state => {
      //state.aprByStakeIsLoading = true;
    },
    [addOrgAccess.fulfilled]: (state, action) => {
      //state.aprByStakeIsLoading = false;
      state.orgProfile = action.payload;
    },
    [addOrgAccess.rejected]: (state, action) => {
      //state.aprByStakeIsLoading = false;
    },

    [addOrgProfile.pending]: state => {
      //state.aprByStakeIsLoading = true;
    },
    [addOrgProfile.fulfilled]: (state, action) => {
      //state.aprByStakeIsLoading = false;
      state.orgProfiles = action.payload;
    },
    [addOrgProfile.rejected]: (state, action) => {
      //state.aprByStakeIsLoading = false;
    },

    [addAccount.pending]: state => {
      //state.aprByStakeIsLoading = true;
    },
    [addAccount.fulfilled]: (state, action) => {
      //state.aprByStakeIsLoading = false;
      state.orgProfile = action.payload;
    },
    [addAccount.rejected]: (state, action) => {
      //state.aprByStakeIsLoading = false;
    },

    [getAprByStake.pending]: state => {
      state.aprByStakeIsLoading = true;
    },
    [getAprByStake.fulfilled]: (state, action) => {
      state.aprByStakeIsLoading = false;
      state.aprByStake = action.payload;
    },
    [getAprByStake.rejected]: (state, action) => {
      state.aprByStakeIsLoading = false;
    },

    [getAprByLevel.pending]: state => {
      state.aprByLevelIsLoading = true;
    },
    [getAprByLevel.fulfilled]: (state, action) => {
      state.aprByLevelIsLoading = false;
      state.aprByLevel = action.payload;
    },
    [getAprByLevel.rejected]: (state, action) => {
      state.aprByLevelIsLoading = false;
    },

    [getBlockTime.pending]: state => {
      state.blockTimeIsLoading = true;
    },
    [getBlockTime.fulfilled]: (state, action) => {
      state.blockTimeIsLoading = false;
      state.blockTimeByHour = action.payload;
    },
    [getBlockTime.rejected]: (state, action) => {
      state.blockTimeIsLoading = false;
    },

    [getRewardsByHour.pending]: state => {
      state.rewardsIsLoading = true;
    },
    [getRewardsByHour.fulfilled]: (state, action) => {
      state.rewardsIsLoading = false;
      state.rewardsByHour = action.payload;
    },
    [getRewardsByHour.rejected]: (state, action) => {
      state.rewardsIsLoading = false;
    },

    [getResponsive.pending]: state => {
      state.responsiveIsLoading = true;
    },
    [getResponsive.fulfilled]: (state, action) => {
      state.responsiveIsLoading = false;
      state.responsiveByHour = action.payload;
    },
    [getResponsive.rejected]: (state, action) => {
      state.responsiveIsLoading = false;
    },

    [getAccountDetails.pending]: state => {
      state.isLoading = true;
    },
    [getAccountDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountDetails = action.payload;
    },
    [getAccountDetails.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getOrgProfiles.pending]: state => {
      state.isLoading = true;
    },
    [getOrgProfiles.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orgProfiles = action.payload;
      
    },
    [getOrgProfiles.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getOrgProfile.pending]: state => {
      state.isLoading = true;
    },
    [getOrgProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orgProfile = action.payload;
    },
    [getOrgProfile.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getMinerBlocksHourly.pending]: state => {
      state.isLoading = true;
    },
    [getMinerBlocksHourly.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.minerBlocksHourly = action.payload;
    },
    [getMinerBlocksHourly.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getLastMinerBlock.pending]: state => {
      state.isLoading = true;
    },
    [getLastMinerBlock.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.lastMinerBlock = action.payload;
    },
    [getLastMinerBlock.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [updateSendStartCommand.pending]: state => {
      state.isLoading = true;
    },
    [updateSendStartCommand.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selPhala.send_start_command = action.payload.send_start_command;
    },
    [updateSendStartCommand.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [updateSendStopCommand.pending]: state => {
      state.isLoading = true;
    },
    [updateSendStopCommand.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selPhala.send_stop_command = action.payload.send_stop_command;
    },
    [updateSendStopCommand.rejected]: (state, action) => {
      state.isLoading = false;
    },

   

    [updateSendRestartCommand.pending]: state => {
      state.isLoading = true;
    },
    [updateSendRestartCommand.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selPhala.send_restart_command = action.payload.send_restart_command;
    },
    [updateSendRestartCommand.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getAllPhala.pending]: state => {
      state.isLoading = true;
    },
    [getAllPhala.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.phala = action.payload;
      state.lastTime = new Date().getTime();
      state.hasLoaded = true;
    },
    [getAllPhala.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [updateSetSendNotification.pending]: state => {
      state.isLoading = true;
    },
    [updateSetSendNotification.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selPhala.send_down_notification =
        action.payload.send_down_notification;
    },
    [updateSetSendNotification.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [updateSetAutoRestart.pending]: state => {
      state.isLoading = true;
    },
    [updateSetAutoRestart.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selPhala.auto_restart_on_sync = action.payload.auto_restart_on_sync;
    },
    [updateSetAutoRestart.rejected]: (state, action) => {
      state.isLoading = false;
    }
  }
});

export const {
  setSearchState,
  setSearchQuery,
  setShowOrgs,
  updateSelPhala,
  rightMenuShow,
  rightMenuHide,
  updateSelAccount,
  setNetworkChart,
  setWorkerChart,
  setNetworkChartTitle,
  setAprChartType,
  setWorkerChartTitle,
  setPoolChart,
  setAprChart,
  setAccountCharts,
  setLastTime,
  setWorkerLog,
  togglePhalaSelected,
  removeAllPhalaSelected,
  addAllPhalaSelected
} = phalaSlice.actions;

export default phalaSlice.reducer;
