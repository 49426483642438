import React, { useEffect, useState } from 'react';

import { ButtonGroup, Button } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBlockTime,
  setNetworkChartTitle,
  getResponsive,
  getHourlyStats
} from '../../phalaSlice';
import '../../../../App.css';

const WithdrawnWidget = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [poolWithdrawalArray, setPoolWithdrawal] = useState([0]);
  const [ownerRewardsWithdrwawnArray, setOwnerRewardsWithdrwawn] = useState([
    0
  ]);
  const [rewardsToStakerArray, setRewardsToStaker] = useState([0]);
  const [rewardsToOwnerArray, setRewardsToOwner] = useState([0]);
  const [chartInterval, setChartInterval] = useState('hour');
  const [activeChart, setActiveChart] = useState('rewards'); // or 'withdrawn'

  const [isLoading, setIsLoading] = useState(false); // Simplified loading state
  const { networkChartTitle, hourlyStats, blockTimeIsLoading } = useSelector(
    store => store.phala
  );

  const toggleChart = () => {
    setActiveChart(prevState =>
      prevState === 'rewards' ? 'withdrawn' : 'rewards'
    );
  };

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'top', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
      },
      formatter: function (params) {
        let result = '';
        params.forEach((param) => {
          // Assuming 'param' contains the series name and data value
          const roundedValue = Math.round(param.value).toLocaleString();
          result += `${param.marker}${param.seriesName}: ${roundedValue}<br/>`;
        });
        return result;
      },
    },
    toolbox: {
      feature: {},
      iconStyle: {
        borderColor: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    legend: {
      data: ['Pool', 'Owner'],
      top: '25',
      inactiveColor: '#555555',

      textStyle: {
        color: 'white'
      }
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          opacity: 0.08 // set the opacity of the line to 50%
        }
      },
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    media: [
      {
        // For smaller screens
        query: {
          maxWidth: 500, // adjust this value based on your needs
        },
        option: {
          yAxis: {
            axisLabel: {
              show: false // hide y-axis labels
            }
          },
          grid: {
            left: '0%', // reduce grid margin on the left
            right: '0%', // reduce grid margin on the right
          },
        },
      },
    ],
    series: [
      {
        name: 'Pool',
        data: poolWithdrawalArray,
        type: 'bar',
        smooth: false,
        showSymbol: false,
        color: '#E88834'
      },
      {
        name: 'Owner',
        data: ownerRewardsWithdrwawnArray,
        type: 'bar',
        smooth: false,
        showSymbol: false,
        color: '#D1BE65'
      }
    ]
  };

  

  useEffect(() => {
    console.log('data Changed');
    let ownerRW = [];
    let pWithdrawal = [];
    
    let tcat = [];

    for (let m of hourlyStats) {
      //tcat.push(String(m._id))
      ownerRW.push(m.ownerRewardsWithdrawn);
      pWithdrawal.push(m.poolWithdrawal);
      
      let dt = new Date(Math.floor(m.minTime));
      console.log('Chart Interval: ' + chartInterval);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,

            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);
    setPoolWithdrawal(pWithdrawal);
    setOwnerRewardsWithdrwawn(ownerRW);
  }, [hourlyStats]);

  useEffect(() => {
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (networkChartTitle === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (networkChartTitle === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (networkChartTitle === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (networkChartTitle === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    if (networkChartTitle === '1y') {
      let start = Math.floor(end - 31557600000); // 1 year (365.25 days)
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('1y');
    }
    dispatch(getHourlyStats(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (e === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (e === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('7d');
    }
    if (e === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (e === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    if (e === '1y') {
      let start = Math.floor(end - 31557600000); // 1 year (365.25 days)
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('1y');
    }
    dispatch(getHourlyStats(data));
  };
  return (
    <section className="leftChartCard pb-0">
      <div className="row">
        <h3 className="text-start col-10 ps-4"> Withdrawn </h3>

        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('1y')}
              className={chartInterval === '1y' ? 'active' : ''}
            >
              1y
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div>
        {blockTimeIsLoading === true ? (
          <div class="position-absolute bottom-50 end-50">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        ) : (
          ''
        )}
    <div class="mt-3">
          <ReactEChart option={eChartsOption} />
        </div>

        
      </div>
     
      
    </section>
  );
};

export default WithdrawnWidget;