 import React, { useEffect, useState } from 'react';

import { Card, Dropdown, DropdownButton } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix

import { useDispatch, useSelector } from 'react-redux';
import { getAprByLevel,getAprByStake, getRewardsByHour,setAprChartType,setWorkerChartTitle } from '../phalaSlice';

const AprByLevelMobile = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [Level1Array, setLevel1] = useState([]);
  const [Level2Array, setLevel2] = useState([]);
  const [Level3Array, setLevel3] = useState([]);
  const [Level4Array, setLevel4] = useState([]);
  const [Level5Array, setLevel5] = useState([]);
  const [AvgArray, setAvg] = useState([]);

  const [titleText, setTitle] = useState('7d');
  const [chartInterval, setChartInterval] = useState('hour');

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { aprByLevel,aprByLevelIsLoading,workerChartTitle } = useSelector(store => store.phala);
  const avgAprLvlWidget = aprByLevel.reduce((acc, cur) => acc + cur.averageApr, 0) / AvgArray.length;
  const level1AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level1, 0) / Level1Array.length;
  const level2AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level2, 0) / Level2Array.length;
  const level3AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level3, 0) / Level3Array.length;
  const level4AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level4, 0) / Level4Array.length;
  const level5AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level5, 0) / Level5Array.length;

  const setAprChartHandler = (chartType) => {
    dispatch(setAprChartType(chartType));
  };

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'bottom', // set the tooltip position to top
      offset: [300, 300], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
    }
    },
    toolbox: {
      feature: {
        
      },
      iconStyle: {
        borderColor: 'white',
      }
    },

    legend: {
      data: ['Average', 'Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'],
      top: '25',
      inactiveColor: '#555555',
      
      top:'auto',
      selected: {
        'Level 1': false,
        'Level 2': false,
        'Level 3': false,
        'Level 4': false,
        'Level 5': false,
        'Average': true,
      },
      textStyle: {
        color: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        opacity: 0.5,
        show: false  
      },
    },

    yAxis: {
      type: 'value',
      
      splitLine: {
        lineStyle: {
            opacity: 0.08  
        }
    },
      axisLabel: {
            opacity: 0.5,
            show: false
    },
    },
    grid: [
      {
        left: '0%',
        right: '0%',
        height: '70%'
      }
    ],
    series: [
      {
        name: 'Level 1',
        data: Level1Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#3E1FCC'
      },
      {
        name: 'Level 2',
        data: Level2Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#26E4FF'
      },
      {
        name: 'Level 3',
        data: Level3Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#CCB500'
      },
      {
        name: 'Level 4',
        data: Level4Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color:'#CC5900'
      },
      {
        name: 'Level 5',
        data: Level5Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color:'#9D1FCC'
      },
      {
        name: 'Average',
        data: AvgArray,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#0ACC77'
      }
    ]
  };
  useEffect(() => {
    console.log('data Changed');
    let l1 = [];
    let l2 = [];
    let l3 = [];
    let l4 = [];
    let l5 = [];
    let avg = [];
    let tcat = [];
    for (let m of aprByLevel) {
      l1.push(parseInt(m.level1));
      l2.push(parseInt(m.level2));
      l3.push(parseInt(m.level3));
      l4.push(parseInt(m.level4));
      l5.push(parseInt(m.level5));
      avg.push(parseInt(m.averageApr));
      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === "hour"){
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,
  
            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
      
        );
      } else {
          tcat.push(
              dt.toLocaleString('en-us', {
                
                month: 'short',
                day: 'numeric',
                year: 'numeric'
              })
          
            );
      }
    }

    setCategory(tcat);
    setLevel1(l1);
    setLevel2(l2);
    setLevel3(l3);
    setLevel4(l4);
    setLevel5(l5);
    setAvg(avg);
  }, [aprByLevel]);
  useEffect(() => {
    let end = Math.floor(new Date().getTime())
    let start = Math.floor(end - 604800000); // 1 week
    let data = {}
    if (workerChartTitle === '24h') {
      
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }

    }
    if (workerChartTitle === '7d') {
      
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
      
    }
    if (workerChartTitle === '30d') {
     
      let start = Math.floor(end - (2629743000) ); // 30.44 days 
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
    }
    if (workerChartTitle === '90d') {
      
      
      let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
      data = {
        "start": start,
        "end": end,
        "interval": "day"
      }
    }
    dispatch(getAprByLevel(data));
    dispatch(getRewardsByHour(data));
    dispatch(getAprByStake(data));
    
  }, []);




  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {}
    let end = Math.floor(new Date().getTime())
    if (e === '24h') {
      setTitle('24h');
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }

    }
    if (e === '7d') {
      setTitle('7d');
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
      
    }
    if (e === '30d') {
      setTitle('30d');
      let start = Math.floor(end - (2629743000) ); // 30.44 days 
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
    }
    if (e === '90d') {
      setTitle('90d');
      
      let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
      data = {
        "start": start,
        "end": end,
        "interval": "day"
      }
    }
    dispatch(getAprByLevel(data));
    dispatch(getRewardsByHour(data));
    dispatch(getAprByStake(data));

    dispatch(setWorkerChartTitle(e))


  };

  return (
    <section>
      <div className='row'>
      <div class="col-sm-1"></div>
        <h3 className='text-start col-10 ps-5' >APR (Worker Level)</h3>
        <div className='col-1 d-flex justify-content-end'>
        
        
        <DropdownButton onSelect={handleSelect} id="chartSelection" title={workerChartTitle} variant="dark">
          <Dropdown.Item eventKey="24h">24h</Dropdown.Item>
          <Dropdown.Item eventKey="7d">7d</Dropdown.Item>
          <Dropdown.Item eventKey="30d">30d</Dropdown.Item>
          <Dropdown.Item eventKey="90d">90d</Dropdown.Item>
        </DropdownButton>
        </div>
      </div>
      <div>
        {aprByLevelIsLoading === true?
        (
          <div class="position-absolute bottom-50 end-50">
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        ):("")}
        

        <div class="mt-3">
          <ReactEChart option={eChartsOption} />
        </div>
        <div class="row m-3">
        <div class='col-7'></div>
        <div class="btn-group col-5 p-0 d-flex justify-content-end" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="aprStakeChart" autocomplete="off" checked/>
          <label class="btn btn-outline-primary" for="aprRadio">Level</label>
          <input type="radio" class="btn-check" name="btnradio" id="delegationLevelChart" autocomplete="off"/>
          <label class="btn btn-outline-primary" for="delegationRadio" onClick={() => setAprChartHandler("aprLevelChart")}>Delegation</label>
        </div>
        </div>
        <div className='row d-flex justify-content-center pt-4 pb-4 mt-4 bg-dark' >
          <card className='col-4 text-center ps-4'>
            <h5 className='header-subtle'>Level 1</h5>
            <h3>{Number((level1AprWidget).toFixed(2)).toLocaleString("en-US")  + "%"}</h3>
          </card>
          <card className='col-4 text-center'>
            <h5 className='header-subtle'>Level 2</h5>
            <h3>{Number((level2AprWidget).toFixed(2)).toLocaleString("en-US")  + "%"}</h3>
          </card>
          <card className='col-4 text-center'>
            <h5 className='header-subtle'>Level 3</h5>
            <h3>{Number((level3AprWidget).toFixed(2)).toLocaleString("en-US")  + "%"}</h3>
          </card>
            <card className='col-4 text-center pt-3 '>
            <h5 className='header-subtle'>Level 4</h5>
            <h3>{Number((level4AprWidget).toFixed(2)).toLocaleString("en-US")  + "%"}</h3>
          </card>
          <card className='col-4 text-center pt-3'>
            <h5 className='header-subtle'>Level 5</h5>
            <h3>{Number((level5AprWidget).toFixed(2)).toLocaleString("en-US")  + "%"}</h3>
          </card>
        </div>
      </div>
    </section>
    
  );
  
};

export default AprByLevelMobile;
 